import { ref, watch, computed, onMounted } from "@vue/composition-api";
import store from "@/store";
import axiosIns from "@/libs/axios";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useChasingsList() {
  // Use toast
  const toast = useToast();

  const refChasingsListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "id", label: "編號", sortable: true },
    { key: "client_loan_id", label: "合約編號", sortable: true },
    // { key: "client_id", label: "客戶編號", sortable: true },
    { key: "pay_date", label: "供款日", sortable: true },
    { key: "postpone_date", label: "延期日期", sortable: true },
    { key: "period", label: "期數", sortable: true },
    { key: "actual_amount", label: "供款金額", sortable: true },
    { key: "actual_paid_amount", label: "已償還金額", sortable: true },
    { key: "remaining_amount", label: "尚欠金額", sortable: true },
    // { key: "client.name_en", label: "客戶名稱(英文)", sortable: true },
    { key: "client_loan.name_cn", label: "客戶名稱(中文)", sortable: true },
    { key: "client.id_number", label: "身分證號碼", sortable: true },
    { key: "client_loan.loan_information.mobile", label: "手提電話1", sortable: true },
    { key: "client_loan.loan_information.tel", label: "手提電話2", sortable: true },
    { key: "actions", stickyColumn: true, label: "操作" },
  ];

  const perPage = ref(10);
  const totalRow = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("pay_date");
  const isSortDirDesc = ref(false);

  const dataMeta = computed(() => {
    const localItemsCount = refChasingsListTable.value
      ? refChasingsListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalRow.value,
    };
  });

  const refetchData = () => {
    refChasingsListTable.value.refresh();
  };

  const chasingsList = ref(null);

  const filter_loan_date_from = ref(null);
  const filter_loan_date_to = ref(null);
  const idFilter = ref(null)

  watch(
    [currentPage, perPage, searchQuery, 
      filter_loan_date_from,
      filter_loan_date_to,
      idFilter
    ],
    () => {
      refetchData();
    }
  );
  const oneYear = new Date()
  oneYear.setFullYear(oneYear.getFullYear() + 1);
  const year = oneYear.getFullYear();
  const month = String(oneYear.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
  const day = String(oneYear.getDate()).padStart(2, '0');

  // Format the date as "yyyy-mm-dd"
  const formattedDate = `${year}-${month}-${day}`;

  const fetchChasings = (ctx, callback) => {
    store
      .dispatch("chasing/fetchChasings", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        id_number: idFilter.value,
        // is_late : 1,
        dateFrom: filter_loan_date_from.value,
        dateTo: filter_loan_date_to.value ? filter_loan_date_to.value : formattedDate,
      })
      .then((response) => {
        const { payment_schedules, total } = response.data;
        callback(payment_schedules);
        chasingsList.value = payment_schedules
        totalRow.value = total;
      })
      .catch((err) => {
        console.log(err);
        toast({
          component: ToastificationContent,
          props: {
            title: "獲取貸款清單時出錯",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  }; 
  const receiveMethodOptions = ref([]);
  const receiveAccountOptions = ref([]);

  const refetchOption = () => {
    store
      .dispatch("app/fetchOptionList", {
        receive_method: true,
      })
      .then((response) => {
        receiveMethodOptions.value = response.data.receive_methods;
      });

    store
      .dispatch("chasing/fetchReceiveAccounts")
      .then((response) => {
        receiveAccountOptions.value = response.data.receive_accounts
      })
      .catch((error) => {
        if (error.response.status === 404) {
          receiveAccountOptions.value = undefined;
        }
      });
  };


  onMounted(() => {
    refetchOption();
  });

  return {
    fetchChasings,
    tableColumns,
    perPage,
    totalRow,
    currentPage,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    dataMeta,
    chasingsList,
    refChasingsListTable,
    ability,
    refetchData,
    idFilter,
    receiveMethodOptions,
    receiveAccountOptions,
    filter_loan_date_from,
    filter_loan_date_to,
  };
}
