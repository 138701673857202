<template>
  <div class="">
    <!-- <modal-filter
      :filter_loan_date_from.sync="filter_loan_date_from"
      :filter_loan_date_to.sync="filter_loan_date_to"
    /> -->

    <b-card class="">
      <div class="d-flex flex-wrap align-items-center justify-content-between">
        <div class="d-flex mb-1 mb-sm-0 align-items-center">
          <h4 class="font-weight-bolder ml-1 text-nowrap mb-0">
            <font-awesome-icon
              class="mr-50"
              icon="fa-solid fa-money-bill-alt"
            />
            追款
          </h4>
          <div class="d-flex ml-3 align-items-center">
            <label>顯示</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>條目</label>
          </div>
        </div>
        <div
          class="d-flex mobile-w100 align-items-center flex-sm-nowrap flex-wrap"
        >  
        <b-form-input
            v-model="idFilter"
            style="width: 300px"
            class="search-form mb-1 mb-sm-0 mr-sm-1"
            placeholder="搜尋身分證號碼"
          />
          <b-form-input
            v-model="searchQuery"
            style="width: 300px"
            class="search-form mb-1 mb-sm-0 mr-sm-1"
            placeholder="搜尋追款"
          />
          <!-- <b-button
            variant="primary"
            class="mr-sm-1 mobile-w100 mb-1 mb-sm-0 mr-sm-1 d-flex align-items-center text-nowrap"
            @click="$bvModal.show('modal-filter')"
            ><feather-icon
              class="mr-1"
              icon="filterIcon"
              size="18"
            ></feather-icon
            >篩選</b-button
          > -->
        </div>
      </div>
    </b-card>
    <b-card no-body class="card-statistic">
      <b-card-body class="p-2">
        <b-table
          ref="refChasingsListTable"
          class="position-relative sticky-rtl-tb"
          :items="fetchChasings"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="未找到匹配的記錄"
          :sort-desc.sync="isSortDirDesc"
          tbody-tr-class="defer_peroid"
        >

          <template #cell(client_loan_id)="data">
            <b-link
              class="color1"
              v-if="ability.can('update', 'contracts') || true"
              :to="{ name: 'contracts-edit', params: { id: data.item.client_loan_id } }"
            >
              {{ data.item.client_loan && data.item.client_loan.contract_number }}
            </b-link>
          </template>

          <template #cell(client.id_number)="data">
            <span
              class="id-number color1 cursor-pointer"
              @click="
                setIdNumber(data.item.client && data.item.client.id_number)
              "
              >{{ data.item.client && data.item.client.id_number }}</span
            >
          </template>

          <template #cell(remaining_amount)="data">
            <div
              v-if="
                Number(data.item.actual_amount) -
                  Number(data.item.actual_paid_amount) >
                0
              "
            >
              {{
                Math.round(
                  Number(data.item.actual_amount) -
                  Number(data.item.actual_paid_amount)
                )
              }}
            </div>
            <div v-else>0</div>
          </template>

          <template #cell(actions)="data">
              <b-link
                v-if="ability.can('update', 'repayment') || true"
                :to="{
                  name: 'repayment-edit',
                  params: {
                    contract_id: data.item.client_loan_id,
                    id: data.item.id,
                  },
                }"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">還款</span>
              </b-link>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >在{{ dataMeta.of }}個記錄中，正在顯示 {{ dataMeta.from }} 至
                {{ dataMeta.to }}個記錄</span
              >
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRow"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>
    
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import ModalFilter from "../modal/ModalFilter";
import useChasingsList from "./useChasingsList";
import chasingStoreModule from "../chasingStoreModule";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    ModalFilter,
  },
  data() {
    return {};
  },
  methods: {
    setIdNumber(id) {
      if (this.idFilter == id) {
        this.idFilter = null;
      } else {
        this.idFilter = id;
      }
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "chasing";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, chasingStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const {
      fetchChasings,
      tableColumns,
      perPage,
      totalRow,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      chasingsList,
      refChasingsListTable,
      ability,
      refetchData,
      idFilter,
      receiveMethodOptions,
      receiveAccountOptions,
      filter_loan_date_from,
      filter_loan_date_to,
    } = useChasingsList();

    return {
      fetchChasings,
      tableColumns,
      perPage,
      totalRow,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      dataMeta,
      chasingsList,
      refChasingsListTable,
      ability,
      refetchData,
      idFilter,
      receiveMethodOptions,
      receiveAccountOptions,
      filter_loan_date_from,
      filter_loan_date_to,
    };
  },
};
</script>
    
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

// @media screen and (max-width: 768px) {

// }
// @media screen and (max-width: 575px) {

// }
</style>
    
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@/assets/scss/variables/_variables.scss";


</style>
    