import axiosIns from "@/libs/axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchChasings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/payment_schedule/late/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },

    fetchReceiveAccounts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get("/receive_account/list", { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error));
      });
    },
  }
};
