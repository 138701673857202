<template>
  <b-modal
    id="modal-filter"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    scrollable
    title=""
    title-class="font-weight-bolder"
    body-class="common_modal modal_visible"
    hide-header
    hide-footer
    no-close-on-backdrop
    size="sm"
    hide-header-close
  >
    <div>
      <h3 class="mt-1 mb-2 d-flex justify-content-center align-items-center">
        <font-awesome-icon class="mr-50" icon="fa-solid fa-filter" />
        篩選
      </h3>
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="onSubmit">
          <b-row>
            <b-col class="mb-1" cols="12">
              <div class="d-flex align-items-center mb-1">
                <div style="width: calc(70% + 2px)">借貸日期：</div>
                <flat-pickr
                  style="width: 32%"
                  v-model="update.filter_loan_date_from"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                  placeholder="輸入YYYY-MM-DD"
                />

                <div style="width: calc(10% + 2px); text-align: center">-</div>

                <!-- filter_range_id_end-->
                <flat-pickr
                  v-model="update.filter_loan_date_to"
                  class="form-control"
                  :config="{
                    allowInput: true,
                    altInput: true,
                    altFormat: 'Y-m-d',
                    dateFormat: 'Y-m-d',
                  }"
                  placeholder="輸入YYYY-MM-DD"
                />
              </div>
            </b-col>
          </b-row>

          <div class="d-flex mt-2 align-items-center justify-content-center">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-danger"
              class="mr-2"
              @click="hide"
            >
              <feather-icon size="16" class="mr-50" icon="XIcon" />
              取消
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              <feather-icon size="16" class="mr-50" icon="CheckCircleIcon" />
              確認
            </b-button>
          </div>
        </b-form>
      </b-overlay>
    </div>
  </b-modal>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { ref, watch, onUnmounted, onMounted } from "@vue/composition-api";
import "animate.css";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BButton,
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCardBody,
    BCard,
    BFormInvalidFeedback,
    vSelect,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-filter");
    },
    onSubmit() {
      this.$emit("update:filter_loan_date_from", this.update.filter_loan_date_from);
      this.$emit("update:filter_loan_date_to", this.update.filter_loan_date_to);
      this.hide();
    },
  },
  props: {
    filter_loan_date_from: {},
    filter_loan_date_to: {},
  },
  data() {
    return {
      loading: false,
      update: {
        filter_loan_date_from: null,
        filter_loan_date_to: null,
      },
     
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
